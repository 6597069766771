export const concat = (...functionList) => {
  return (value) => {
    for (let i = 0; i < functionList.length; i++) {
      const result = functionList[i](value)
      if (result !== '') return result
    }
    return ''
  }
}

var toValidate = {}
export const ref = name => element => (toValidate[name] = element)

export const validate = () => {
  let validated = true
  Object.keys(toValidate).map(key => {
    const input = toValidate[key]
    if (input === null) return
    if (input.props.validation && input.props.validation(input.props.value) !== '') {
      validated = false
    }
    if (!input.state.touched) input.setState({touched: true})
  })
  return validated
}

export const notEmpty = (value) => value === '' ? 'Ta wartość jest wymagana' : ''
export const longer = (length) => (value) => value.length < length ? `Długość musi być większa niż ${length}` : ''
export const shorter = (length) => (value) => value.length > length ? `Długość musi być mniejsza niż ${length} znaki` : ''
export const email = (value) => !validateEmail(value) ? `Wpisz poprawny adres email` : ''
export const nip = v => v.length === 0 || (v.length === 10 && isNumeric(v)) ? '' : 'Wpisz poprawny NIP'
export const numeric = v => isNumeric(v) ? '' : 'Wartość musi być liczbowa'
export const samePassword = (value1) => (value) => value1 !== value ? `Hasła muszą być takie same` : ''
export const notSamePassword = (value1) => (value) => value1 === value ? `Hasła nie mogą być takie same` : ''
export const emailUnique = (value1) => (value) => value1 === true ? 'Podany adres e-mail już istnieje' : ''

// Examples
// concat(notEmpty, shorter(10))

function validateEmail (email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

function isNumeric(n) {
  return n === '' || (!isNaN(parseFloat(n)) && isFinite(n))
}