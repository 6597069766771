import React from 'react'
import {Route, Link} from 'react-router-dom'
import { signUp } from '../services/signUp'
import * as C from 'storwork-components'
import * as V from '../services/validation'
import {GlobalStorage} from 'storwork-stores'

class Register extends React.Component {
  state = {
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    company: ''
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    if (V.validate()) {
      this.setState({
        loading: true
      })
      signUp(this, data => {
        this.setState({
          status: true
        })
      })
    } else {
      alert('Wypełnij poprawnie wszystkie pola')
    }
  }

  render () {
    if (this.state.status) {
      GlobalStorage.refreshCache(data => {
        console.log('globalstorage')
        window.location.assign('/contacts')
      })
      return null
    }

    return (
      <C.Box style={{marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'}}>
        <C.Box>Rejestracja</C.Box>
        <form onSubmit={this.handleSubmit}>
          <C.Box>
            <label htmlFor='password'>Adres e-mail</label><br />
            <input id='email' type='text' label='email' name='email' autoComplete='off' onChange={this.handleChange} />
          </C.Box>
          <C.Box>
            <label htmlFor='password'>Hasło</label><br />
            <input id='password' type='password' label='hasło' name='password' onChange={this.handleChange} />
          </C.Box>
          <C.Box>
            <label htmlFor='password'>imię</label><br />
            <input id='first_name' type='text' name='first_name' onChange={this.handleChange} />
          </C.Box>
          <C.Box>
            <label htmlFor='password'>Nazwisko</label><br />
            <input id='last_name' type='text' name='last_name' onChange={this.handleChange} />
          </C.Box>
          <C.Box>
            <label htmlFor='company'>Skrócona nazwa organizacji</label><br />
            <input id='company' type='text' name='company' onChange={this.handleChange} />
          </C.Box>
          <C.Box>
            <input type='submit' value='Zaloguj' />
          </C.Box>
        </form>
        <C.Box>
          Masz już konto? <Link to='/login'>Zaloguj się</Link>
        </C.Box>
      </C.Box>
    )
  }
}

export default Register;
