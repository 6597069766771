import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { StorworkInit, JWT } from 'storwork-stores'
import Board from './Board'
import Login from './views/Login'
import Register from './views/Register'

const Logout = () => {
  JWT.forget()
  window.location.assign('/login')
}

class App extends Component {

  render() {
    //return <Board />
    return <StorworkInit loggedIn={<Switch>
     <Route exact path='/board/:id' component={Board} />
     <Route path='/logout' component={Logout} />
    </Switch>} loggedOut={<Switch>
      <Route exact path='/login' component={Login} />
      <Route exact path='/register' component={Register} />
      <Route path='/' component={Login} />
    </Switch>} />
  }
}

export default App;
