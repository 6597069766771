// @flow
import type { Quote, QuoteMap } from './types';
import type { DraggableLocation } from '../../src/types';
import getStore from './Store'

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default reorder;

type ReorderQuoteMapArgs = {|
  quoteMap: QuoteMap,
  source: DraggableLocation,
  destination: DraggableLocation,
|};

export type ReorderQuoteMapResult = {|
  quoteMap: QuoteMap,
|};

export const reorderQuoteMap = ({
  quoteMap,
  source,
  destination,
}) => {
  const current = quoteMap[source.droppableId];
  const next = quoteMap[destination.droppableId];
  const target = current.cards[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(
      current.cards,
      source.index,
      destination.index,
    );
    const result = {
      ...quoteMap,
      [source.droppableId]: {
        name: current.name,
        cards: reordered
      },
    };
    return {
      quoteMap: result,
    };
  }

  // moving to different list

  // remove from original
  current.cards.splice(source.index, 1);
  next.cards.splice(destination.index, 0, target);

  const result = {
    ...quoteMap,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };
  console.log(result);
  getStore().setContent('data',result)
  getStore().sync()
  console.log(JSON.parse(getStore().data.search[0].content).data);
  return {
    quoteMap: result,
  };
};
