import JWT from "jwt-client";
export const signUp = (that, callback = () => { }) => {
  console.log(JWT.get());
  console.log("hey");
  console.log(that);
  fetch('https://b2b.storwork.pl/graphql', {
    method: "POST",
    headers: {
      Accept: "application/json",
      "content-type": "application/json"
    },
    body: JSON.stringify({
      query: `
          mutation Registration($email: String!, $first_name: String, $last_name: String, $additional_info: String, $password: String!){
            registerUser(email: $email, first_name: $first_name, last_name: $last_name, additional_info: $additional_info, password: $password)
          }
          `,
      variables: {
          email: that.state.email,
          first_name: that.state.first_name,
          last_name: that.state.last_name,
          password: that.state.password,
          additional_info: JSON.stringify({
            company: that.state.company ? that.state.company : "Moja organizacja",
            phone: that.state.phone
          })
      }
    })
  })
    .then(response => {
      return response.json().then(data => ({
        ...data,
        status: response.status
      }));
    })
    .then(data => {
      const token = data.data.registerUser;
      console.log("ponizej daata z signup");
      console.log(data);
      JWT.keep(token);
      callback(JWT.remember().claim.organization.id, () => that.setState({ status: true }))
      that.setState({ loading: false })
    })
    .catch(err => console.log('error with remembering org id: ' + err));
};

export default signUp;
