// @flow
import React, { Component } from 'react';
import styled from 'react-emotion';
import {observer} from 'mobx-react'
import _ from 'lodash'
import { grid, colors, borderRadius } from './constants';
import { Draggable } from 'react-beautiful-dnd';
import CardList from './CardList'
import getStore from './Store'
const uuidv4 = require('uuid/v4')

const Container = styled('div')`
  margin: ${grid}px;
  display: flex;
  flex-direction: column;
`;

const Header = styled('div')`
  padding: 10px 10px 5px 10px;
  font-weight: bold;
  min-height:15px;
`;

export default observer(class Column extends Component {
  state = {
    edited: false
  }
  startEdit = () => {
    this.setState({
      edited: true
    }, () => {
      this.input.focus()
    })
  }
  stopEdit = () => {
    this.setState({
      edited: false
    })
    getStore().sync()
  }
  changeName = name => {
    console.log(this.props.changeColumn + '.name');
    console.log(name)
    //_.set(getStore().data.data, this.props.changeColumn + '.name', name)
    let _data = JSON.parse(getStore().data.search[0].content).data
    _.set(_data, this.props.changeColumn + '.name', name)
    getStore().setContent('data', _data)
    getStore().sync()
  }
  deleteList = () => {
    const data = JSON.parse(getStore().data.search[0].content).data
    delete data[this.props.changeColumn]
    const ordered = JSON.parse(getStore().data.search[0].content).ordered
    const changedOrdered = ordered.filter(el => el !== this.props.changeColumn)
    getStore().setContent('ordered', changedOrdered)
    getStore().setContent('data', data)
    getStore().sync()
    // console.log(data)
    // const changeColumnArray = this.props.changeColumn.split('.').slice(0, -1).join('.')
    // const array = _.get(data, changeColumnArray)
    // array.splice(this.props.index, 1)
    // _.set(data, changeColumnArray, array)
  }
  render() {
    const column = this.props.column;
    const index = this.props.index;
    return (
      <Draggable draggableId={this.props.columnId} index={index}>
        {(provided, snapshot) => (
          <Container innerRef={provided.innerRef} {...provided.draggableProps}>
            <div style={{
                background: '#dfe3e6',
                borderRadius: '3px'
              }}>
            <Header {...provided.dragHandleProps} isDragging={snapshot.isDragging} onClick={this.startEdit}>
              {this.state.edited ? <div>
                <input onBlur={this.stopEdit} ref={input => this.input = input} value={column.name} onChange={e => this.changeName(e.target.value)} />
                <div style={{float: 'right', cursor: 'pointer'}} onClick={this.deleteList}>
                  <i className="fal fa-trash"></i>
                </div>
              </div> : <div>
                {column.name}
              </div>}
            </Header>
            <CardList listId={this.props.columnId} listType="QUOTE" cards={column.cards} changeCards={this.props.changeColumn + '.cards'} />
            <div style={{
                padding: '8px 10px',
                cursor: 'pointer',
                color: 'grey'
              }} onClick={() => {
              const cardsPath = this.props.changeColumn + '.cards'
              const data = JSON.parse(getStore().data.search[0].content).data
              const cards = _.get(data, cardsPath)
              cards.push({
                id: uuidv4().replace(/-/g, ''),
                name: ''
              })
              _.set(data, cardsPath, cards)
              getStore().setContent('data', data)
            }}>
              <i className="fal fa-plus"></i> Dodaj nową kartę...
            </div>
            </div>
          </Container>
        )}
      </Draggable>
    );
  }
})
