import React from 'react';
import styled from 'react-emotion';
import _ from 'lodash'
import TextareaAutosize from 'react-autosize-textarea'
import { borderRadius, colors, grid } from './constants';
import {observer} from 'mobx-react'
import getStore from './Store'

const Container = styled('span')`
  border-radius: ${borderRadius}px;
  background-color: white;
  box-shadow: 2px 2px 1px ${colors.shadow};
  padding: 5px;
  min-height: 40px;
  margin-bottom: ${grid}px;
  user-select: none;
  transition: background-color 0.1s ease;
  /* anchor overrides */
  color: ${colors.black};
  &:hover {
    color: ${colors.black};
    text-decoration: none;
  }
  &:focus {
    outline: 2px solid ${colors.purple};
    box-shadow: none;
  }
  /* flexbox */
  display: flex;
  align-items: center;
`;

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
export default observer(class QuoteItem extends React.PureComponent {
  state = {
    edited: false
  }
  edit = () => {
    this.setState({
      edited: !this.state.edited
    }, () => {
      console.log(this.area)
      if (!this.area) getStore().sync()
      if (this.area) this.area.focus()
    })
  }
  changeCard = content => {
    // _.set(getStore().data.data, this.props.changeCard + '.name', content)
    let _data = JSON.parse(getStore().data.search[0].content).data
    _.set(_data, this.props.changeCard + '.name', content)
    getStore().setContent('data', _data)

  }

  deleteCard = () => {
    // _.set(getStore().data.data, this.props.changeCard + '.name', content)
    const data = JSON.parse(getStore().data.search[0].content).data
    const changeCardArray = this.props.changeCard.split('.').slice(0, -1).join('.')
    const array = _.get(data, changeCardArray)
    array.splice(this.props.index, 1)
    _.set(data, changeCardArray, array)
    getStore().setContent('data', data)

  }
  render() {
    const { card, isDragging, provided } = this.props;
    const md = require('markdown-it')()
            .use(require('markdown-it-checkbox'),{
              divWrap: true,
              divClass: 'cb',
              idPrefix: 'cbx_'
            });
    return this.state.edited ? <React.Fragment>
      <div onClick={this.edit} style={{position: 'fixed', background: 'rgba(0,0,0,0.5)', top: 0, left: 0, bottom: 0, right: 0}} />
      <Container style={{position: 'relative', zIndex: 100}}>
        <TextareaAutosize
          innerRef={area => this.area = area}
          value={card.name}
          onChange={val => this.changeCard(val.target.value)}
          style={{color: 'black', width: '100%', background: 'transparent', border: 0, resize: 'none'}} />
        <i style={{cursor: 'pointer'}} onClick={this.deleteCard} className="fal fa-trash"></i>
      </Container>
    </React.Fragment> : <Container
        onClick={this.edit}
        isDragging={isDragging}
        innerRef={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div dangerouslySetInnerHTML={{__html: md.render(card.name)}} />
      </Container>
  }
})
