import client from 'socket.io-client'
import { decorate, observable } from 'mobx'
import { JWT } from 'storwork-stores'
import { generateData } from './data';

let store = null
export default (id) => {
  if (!store) store = new Store(id)
  return store
}

// const key = JWT.get()
const key = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJvcmdhbml6YXRpb24iOnsiaWQiOjIsIm5hbWUiOiJTdG9yd29yazIiLCJzZXR0aW5ncyI6IntcImN1cnJlbmN5XCI6XCJQTE5cIixcInNob3dfZm9yX293bmVyXCI6dHJ1ZX0iLCJ0b2tlbiI6bnVsbCwiaXNfb3JnYW5pemF0aW9uIjoxLCJjcmVhdGVkX2F0IjoiMjAxOC0wMi0wNiAxMDo1OToxOCIsInVwZGF0ZWRfYXQiOiIyMDE4LTEwLTMwIDIzOjU5OjAzIiwiZGVsZXRlZF9hdCI6bnVsbCwicmVmZXJyZXIiOiIwIiwiYmFsYW5jZSI6Ii0xNzY0In0sInBlcm1pc3Npb24iOiJhZG1pbiIsIm9yZ2FuaXphdGlvbnMiOlt7ImlkIjoyLCJuYW1lIjoiU3RvcndvcmsyIn0seyJpZCI6MjYsIm5hbWUiOiJNb2phIG9yZ2FuaXphY2phIn0seyJpZCI6MjksIm5hbWUiOiJTZWt3ZW5jamEifV0sInVzZXIiOnsiaWQiOjExNCwiZmlyc3RfbmFtZSI6Ik1hdGV1c3oiLCJsYXN0X25hbWUiOiJcdTAxNDF1a29tc2tpIiwibm9ybWFsaXplZF9uYW1lIjoibWF0ZXVzeiBcdTAxNDJ1a29tc2tpIiwiZnVsbF9uYW1lIjoiTWF0ZXVzeiBcdTAxNDF1a29tc2tpIiwicGFzc3dvcmRfdG9rZW4iOm51bGwsInNldHRpbmdzIjoie30iLCJhZGRpdGlvbmFsX2luZm8iOiJ7XCJjb21wYW55XCI6XCJNb2phIG9yZ2FuaXphY2phXCJ9IiwiY3JlYXRlZF9hdCI6IjIwMTgtMDMtMTkgMDk6MjQ6MTIiLCJ1cGRhdGVkX2F0IjoiMjAxOC0wMy0xOSAwOToyNDoxMiIsImF2YXRhciI6ZmFsc2V9LCJzdWIiOjExNCwiaXNzIjoiaHR0cDovLzE4OC4xMTYuMTEuNzIvZ3JhcGhxbCIsImlhdCI6MTU0MDk3NzUwOSwiZXhwIjoxNTQwOTgxMTA5LCJuYmYiOjE1NDA5Nzc1MDksImp0aSI6ImFRaVFCZ1F1bEpISVZScHoifQ.OD0yUsxIfFkuWOTsqLxR6BVRO9YjhPVb28wqfxDx2pI`


const Store = decorate(class _Store {
  data = null
  socket = null
  query = null
  loaded = false
  setQuery = query => {
    this.query = query
    this.socket.emit('newQuery', this.query)
  }
  sync = () => {
    console.log(JSON.stringify(this.data.search[0]))
    this.socket.emit('sync', this.data)
  }
  setContent = (key, value) => {
    let content = JSON.parse(this.data.search[0].content)
    content[key] = value
    this.data.search[0].content = JSON.stringify(content)
    console.log(JSON.stringify(content))
  }

  getData = data => {
    console.log(data);
    if (JSON.parse(data.search[0].content).type === "kanban"){
      this.data = data
      this.loaded = true
    }
  }
  initSocket = (query) => {
    const socket = client('https://mega.storwork.pl', {
      query: {
        authorization: "Bearer " + JWT.get()
      }
    })
    socket.on('connect', () => {
      console.log('Connected')
      this.socket = socket
      this.socket.on('disconnect', function(){
        console.log('Disconnected')
      })
      this.socket.on('newDataLoaded', this.getData)
      if (query) {
        console.log('Initial query set')
        this.setQuery(query)
      }
    })
  }
  constructor(id) {
    console.log(id)
    const query = `{
      search (id:${id}, type:"task") {
        ... on Task {
          id
          name
          content
          indexType
        }
      }
    }`
    this.initSocket(query)
    // this.data = {
    //   ordered: [],
    //   data: {}
    // }

    this.data = {
      search: [
        {
          content: JSON.stringify({
            ordered: [], //['lista1'],
            data: {
              // lista1: {
              //   name: 'Lista 1',
              //   cards: [
              //     {id: 1, name: 'asd'},
              //     {id: 2, name: 'qwe'},
              //     {id: 3, name: 'zxc'},
              //     {id: 4, name: 'tyu'},
              //     {id: 5, name: '1fgh'}
              //   ]
              // }
            }
          })
        }
      ]
    }
    console.log(this.test);
  }
}, {
  data: observable,
  query: observable,
  loaded: observable
})
