
import {JWT} from 'storwork-stores'

// Takes email and login from component and returns status
// Inputs: email, password (from state)
// Returns: status (loggedIn)
// Side effect: jwt-client keeps token if status == loggedIn
export const login = (that) => {
  if (that.state.email && that.state.password) {
    fetch('https://b2b.storwork.pl/graphql', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        query: `
            query Login($email: String!, $password: String!) {
              loginUser(email: $email, password: $password)
            }
          `,
        variables: {
          email: that.state.email,
          password: that.state.password
        }
      })
    })
      .then(response => {
        return response.json()
          .then(data => ({
            ...data,
            status: response.status
          }))
      })
      .then(data => {
        console.log(data)
        if (data.data.loginUser == 'Invalid credentials') {
          that.setState({
            status: false,
            loading: false
          })
          alert('Błędne dane logowania')
        } else {
          const token = data.data.loginUser
          JWT.keep(token)
          that.setState({
            status: true
          })
        }
      })
  }
}

export default login
