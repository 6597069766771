// @flow
import React, { Component } from 'react';
import styled from 'react-emotion';
import { injectGlobal } from 'emotion';
import {observer} from 'mobx-react'
import Column from './Column';
import { colors } from './constants';
import reorder, { reorderQuoteMap } from './reorder';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import getStore from './Store'
const uuidv4 = require('uuid/v4')

const ParentContainer = styled('div')`
  height: ${({ height }) => height};
  overflow-x: hidden;
  overflow-y: auto;
`;

const Container = styled('div')`
  min-height: 100vh;

  /* like display:flex but will allow bleeding over the window width */
  min-width: 100vw;
  display: inline-flex;
`;

export default props => <Board store={getStore(props.match.params.id)} />

const Board = observer(class _Board extends Component {
  componentDidMount() {
    /* stylelint-disable max-empty-lines */
    // eslint-disable-next-line no-unused-expressions
    injectGlobal`
      body {
        background: ${colors.blue.deep};
      }
    `;
    /* stylelint-enable */
  }

  onDragStart = (initial) => {
  };

  onDragEnd = (result) => {

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // reordering column
    if (result.type === 'COLUMN') {
      const ordered = reorder(
        JSON.parse(this.props.store.data.search[0].content).ordered,
        source.index,
        destination.index,
      );

      //this.props.store.data.ordered = ordered
      this.props.store.setContent('ordered', ordered)
      return;
    }
    const data = reorderQuoteMap({
      quoteMap: JSON.parse(this.props.store.data.search[0].content).data,
      source,
      destination,
    });

    //this.props.store.data.data = data.quoteMap
    this.props.store.setContent('data', data.quoteMap)
  };
  render() {
    const content = JSON.parse(this.props.store.data.search[0].content)
    const columns = content.data;
    const ordered = content.ordered;
    const containerHeight = "60vh";

    const board = (
      <Droppable
        droppableId="board"
        type="COLUMN"
        direction="horizontal"
        ignoreContainerClipping={Boolean(containerHeight)}
      >
        {(provided) => (
          <Container innerRef={provided.innerRef} {...provided.droppableProps}>
            {ordered.map((key, index) => (
              <Column
                key={key}
                index={index}
                column={columns[key]}
                changeColumn={key}
                columnId={key}
              />
            ))}
            <div style={{
                width: 150,
                background: 'rgba(0,0,0,0.4)',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }} onClick={() => {
              const key = uuidv4().replace(/-/g, '')
              // this.props.store.data.data[key] = {
              //   name: '',
              //   cards: []
              // }
              let _data = JSON.parse(this.props.store.data.search[0].content).data
              _data[key] = {
                name: '',
                cards: []
              }
              this.props.store.setContent('data', _data)
              //this.props.store.data.ordered.push(key)
              let _ordered = JSON.parse(this.props.store.data.search[0].content).ordered
              _ordered.push(key)
              this.props.store.setContent('ordered', _ordered)
              this.forceUpdate()
            }}>Nowa lista...</div>
          </Container>
        )}
      </Droppable>
    );

    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
      >
      {board}
      </DragDropContext>
    );
  }
})
