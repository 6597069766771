import React from 'react';
import styled from 'react-emotion';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {observer} from 'mobx-react'
import Card from './Card';
import _ from 'lodash'
import { grid, colors } from './constants';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
  padding: ${grid}px;
  border: ${grid}px;
  padding-bottom: 0;
  transition: background-color 0.1s ease, opacity 0.1s ease;
  user-select: none;
  width: 350px;
`;

const DropZone = styled('div')`
  /* stop the list collapsing when empty */
  min-height: 250px;
  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  margin-bottom: ${grid}px;
`;

const ScrollContainer = styled('div')`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px;
`;

/* stylelint-disable block-no-empty */
const Container = styled('div')``;
/* stylelint-enable */

const InnerQuoteList = observer(class _InnerQuoteList extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.cards !== this.props.cards) {
      return true;
    }

    return false;
  }

  render() {
    const {cards} = this.props
    return cards.map((card, index) => (
      <Draggable key={card.id} draggableId={card.id} index={index}>
        {(
          dragProvided,
          dragSnapshot,
        ) => (
          <Card
            key={index}
            index={index}
            changeCard={this.props.changeCards + '.' + index}
            card={card}
            isDragging={dragSnapshot.isDragging}
            provided={dragProvided}
          />
        )}
      </Draggable>
    ));
  }
})

const InnerList = observer(class InnerList extends React.Component {
  render() {
    const { cards, dropProvided, changeCards } = this.props;
    const title = this.props.title ? this.props.title : null;

    return (
      <Container>
        {title}
        <DropZone innerRef={dropProvided.innerRef}>
          <InnerQuoteList cards={cards} changeCards={changeCards} />
          {dropProvided.placeholder}
        </DropZone>
      </Container>
    );
  }
})

export default observer(class QuoteList extends React.Component {
  static defaultProps = {
    listId: 'LIST',
  };
  render() {
    const {
      ignoreContainerClipping,
      internalScroll,
      isDropDisabled,
      listId,
      listType,
      style,
      cards,
      title,
      changeCards
    } = this.props;

    return (
      <Droppable
        droppableId={listId}
        type={listType}
        ignoreContainerClipping={ignoreContainerClipping}
        isDropDisabled={isDropDisabled}
      >
        {(
          dropProvided,
          dropSnapshot,
        ) => (
          <Wrapper
            style={style}
            isDraggingOver={dropSnapshot.isDraggingOver}
            isDropDisabled={isDropDisabled}
            {...dropProvided.droppableProps}
          >
            {internalScroll ? (
              <ScrollContainer>
                <InnerList
                  cards={cards}
                  title={title}
                  dropProvided={dropProvided}
                  changeCards={changeCards}
                />
              </ScrollContainer>
            ) : (
              <InnerList
                cards={cards}
                title={title}
                dropProvided={dropProvided}
                changeCards={changeCards}
              />
            )}
          </Wrapper>
        )}
      </Droppable>
    );
  }
})
